<template>
    <div class="login-container">
        <el-form ref="form" class="login-form" label-width="80px" lable autocomplete="on">
            <div class="title-container">
                <img src="../../assets/image/icon-1024.png" alt="">
                <p class="title2" v-if="true">91OCR</p>
            </div>
            <el-tabs v-model="activeName" @tab-click="handleClick">
                <el-tab-pane label="微信登录" name="first">
                    <div class="erweima wx_dialog_toiast">
                        <!-- 承载二维码容器 -->
                        <div class="wx_dialog_toiast_delltet" id="login_container"></div>
                        <div class="wx_dialog_button_delete" @click="close_wchat_qrcode">
                            <i class="el-icon-circle-close" style="color: red; font-size: 70px"></i>
                        </div>
                    </div>
                </el-tab-pane>
                <el-tab-pane label="手机号登录" name="second" class="phoneLogin">
                    <div class="phone-content">
                        <el-input v-model="phone" tabindex="1" @input = "phone = phone.replace(/[^0-9]/g,'')" placeholder="手机号" prefix-icon="iconfont icon-shouji" class="input-item"></el-input>
                        <div class="input-item">
                            <el-input v-model="code" placeholder="验证码" @input = "code = code.replace(/[^0-9]/g,'')" class="setCode" prefix-icon="iconfont icon-duanxin">
                            </el-input>
                            <el-button plain @click="getCode" class="sendCode" :class="{'hasSend':hasSend}" :aria-readonly="hasSend">{{codeMsg}}</el-button>
                        </div>
                        <el-button style="width:72%;margin-bottom:30px;" type="primary" tabindex="3" @click="onSubmit" v-show="activeName=='second'">登录</el-button>
                    </div>
                    <!-- <div class="input-item item-do">
                        <el-checkbox v-model="checked">自动登录</el-checkbox>
                    </div> -->
                </el-tab-pane>
            </el-tabs>
        </el-form>
        <div class="helper">
            <div class="firstDiv">
            <ul class="helper-each">
                <li><a href="http://www.etoplive.com/developers/regagreement.do" target="_blank">服务协议</a> </li>
                <li><a href="http://www.etoplive.com/developers/regagreement.do" target="_blank">注册协议</a> </li>
                <li><a href="http://www.etoplive.com/about/aboutus.do" target="_blank">关于我们</a> </li>
            </ul>
            <div class="company">© Copyright 2021 All Rights Reserved 北京译图智讯科技有限公司</div>
            </div>
                 <div class="secondDiv" >
                 地址：北京市昌平区黄平路19号龙旗广场2号楼520室     备案号：<a href="https://beian.miit.gov.cn">京ICP备16015798号-4</a>
      <a target="_blank" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=11011402011670" style="margin-left: 10px"><img src="/static/image/bah.png" />&nbsp;&nbsp;京公网安备11011402011501号</a>
            </div>
                    </div>
    </div>
</template>

<script>
    
  export default {
    data () {
      return {
        loading:false,
        phone: '',
        code:'',
        activeName: 'first',
        checked:false,
        hasSend:false,
        codeMsg:'获取验证码',

        Wechat_authorized_login:false,
        DDNS_resolution:'',
        hasBinding:0
      }
    },
    mounted () {
        this.get_wx_qrcode();
    },
    methods: {
        close_wchat_qrcode(){
            console.log("close")
        },
        // 获取微信的登录二维码
        get_wx_qrcode(){
            var origin=window.location.origin,
            call_back_origin = "https://www.91ocr.com"   // window.location.origin
            if(origin.indexOf("etoplive.com")!=-1){
                call_back_origin=call_back_origin+"/api/redirect?env=test"
            }else if(origin.indexOf("91ocr.com")!=-1){
                call_back_origin=call_back_origin+"/index"
            }else if(origin.indexOf("localhost")!=-1||origin.indexOf("192.168")!=-1){
                call_back_origin=call_back_origin+"/api/redirect?env=dev"
            }
            var DDNS_resolution = encodeURIComponent(call_back_origin);
            console.log(DDNS_resolution)
            new WxLogin({
                self_redirect: false,       //该参数决定回调方式。默认是false,即授权后,整个页面发生跳转,true时,只有iframe嵌套部分发生跳转
                id: "login_container",       // 需要承载二维码的容器id  
                appid: "wx59a2a72b81d339fe",
                scope: "snsapi_login",     // 网页授权，目前网页只有这一种方式，静默授权
                redirect_uri: DDNS_resolution,     // 授权后跳转路径，请使用urlEncode对链接进行处理
                state: Math.random(),   // 可防止csrf攻击
                style: "black",
                href: "data:text/css;base64,LmltcG93ZXJCb3ggLnRpdGxlIHsNCiAgICBkaXNwbGF5OiBub25lOw0KfQ0KLmltcG93ZXJCb3ggLnFyY29kZS5saWdodEJvcmRlciB7DQogICAgd2lkdGg6IDIwMHB4Ow0KfQ==", //自定义样式链接，第三方可根据实际需求覆盖默认样式，将css文件转为base64
            });
        },
        getCode(){
            if(!this.phone){
                this.$message({
                    message:"请输入手机号码",
                    type:'warning'
                })
                return
            }
            var reg = /^[1][3,4,5,7,8][0-9]{9}$/; //验证规则
            if(!reg.test(this.phone)){
                this.$message({
                    message:"请输入格式正确的手机号码",
                    type:'warning'
                })
                return
            }
            if(this.hasSend||this.loading){
                return
            }
            this.loading=true
            let fromData=new FormData()
            fromData.append('phoneNumber',this.phone)
            this.$ajax.post("/api/web/login/getVerificationCode",fromData).then(res=>{
                this.loading=false
                if(res.code==200){
                    let aMinute=59
                    let minueGo=setInterval(()=>{
                        this.codeMsg=aMinute+'秒再获取'
                        this.hasSend=true
                        if(aMinute>0){
                            aMinute--
                        }else{
                            clearInterval(minueGo)
                            minueGo=null
                            this.hasSend=false
                            this.codeMsg='获取验证码'
                        }
                    },1000)
                }
            })
            
        },
        handleClick(tag){
            console.log(tag)
        },
        onSubmit () {
            if(!this.phone){
                this.$message({
                    message:"请输入手机号码",
                    type:'warning'
                })
                return
            }
            var reg = /^1[3|4|5|7|8][0-9]{9}$/; //验证规则
            if(!reg.test(this.phone)){
                this.$message({
                    message:"请输入格式正确的手机号码",
                    type:'warning'
                })
                return
            }
            if(!this.code){
                this.$message({
                    message:"请输入验证码",
                    type:'warning'
                })
                return
            }
            let formData=new FormData()
            formData.append('phoneNumber',this.phone)
            formData.append('vcCode',this.code)
            this.$ajax.post("/api/web/login/loginByPhone", formData).then((res) => {
              if (res.code == 200) {
                this.$utils.setSessionStorage('token', res.data.accessToken)
                this.$utils.setSessionStorage('roleCode', res.data.whetherAdmin==1?'admin':'user')
                console.log('login',this.$utils.getSessionStorage("token"))
                this.$notify({title: '提示', message: '登录成功', type: 'success', duration: 1500})
                this.$router.push({path: '/index'})
              } else {
                this.$notify({title: '提示', message: res.msg, type: 'error', duration: 1500})
              }
            }).catch((e) => {
              this.$notify({title: '提示', message: '连接服务器异常!', type: 'error', duration: 1500})
            })
        },
    }
  }
</script>

<style scoped>
.el-tabs /deep/.el-tabs__nav-wrap::after{
    display: none;
    height: 0;
}
.el-tabs /deep/.el-tabs__nav{
    margin-left: 50%;
    transform: translateX(-50%)!important;
    height: 52px;
    margin-top: 10px;
}
.el-tabs /deep/.el-tabs__item{
    width: 160px;
    font-size: 17px
}
.el-tabs /deep/.el-tabs__header{
    margin-bottom: 23px;
}
.el-tabs /deep/.el-tabs__content{
    margin-bottom: 17px;
}
.el-tabs /deep/.el-tabs__active-bar{
    height: 2px;
    margin-bottom: 9px;
}
.input-item {
    width: 72%;
    margin: 0 auto;
    margin-bottom: 33px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.phoneLogin /deep/.el-input__inner::placeholder{
    color: #acacac;
}
    .login-container {
        min-height: 100%;
        width: 100%;
        overflow: hidden;
        background:url(../../assets/image/login-bg.png) no-repeat center;
        background-size: cover;
    }
.phone-content {
    margin-top: 34%;
    transform: translateY(-50%);
}
    .login-form {
        position: absolute;
        width: 433px;
        height: 428px;
        overflow: hidden;
        margin: auto;
        top: 0;
        bottom: 0;
        right: 25%;
        transform: translateX(50%);
        background: #fff;
        border-radius: 4px;
        box-shadow: 3px 3px 20px rgb(202 202 202)
    }
    .sendCode{
        padding: 12px 14px;
    }
    .erweima img {
        width: 180px;
    }
    .tips {
        font-size: 14px;
        color: #fff;
        margin-bottom: 10px;
    }

    .first-of-type {
        margin-right: 16px;
    }

    .title-container {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 50px;
        display: none;
    }

    .title-container>img {
        width: 42px;
    }

    .title1{
        font-size: 28px;
        font-weight: 400;
        color: #eee;
        margin: 0px auto 30px 10%;
        text-align: center;
    }

    .title2{
        font-size: 30px;
        color: rgb(36,36,37);
        padding-left: 10px;
        text-align: center;
        font-weight: bold;
    }
    .cppyright{
        position: relative;
        margin: 40px auto 30px auto;
        text-align: center;
        color: #eee;
        font-size: 14px;
        line-height: 30px;
    }
    .show-pwd {
        position: absolute;
        right: 10px;
        top: 7px;
        font-size: 16px;
        color: #889aa4;
        cursor: pointer;
        user-select: none;
    }

    .iconfont.icon-chenggong.green {
        color: rgb(58,193,126);
        line-height: 36px;
    }
    .input-item.item-do {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 15px;
        margin-bottom: 10px;
    }
    .el-tab-pane /deep/.el-input__icon.iconfont {
        color: #757575;
    }
    #logoImg{
        position: absolute;
        left:20%;
        top:10%;
    }
    .sendCode.hasSend{
        color: #409EFF;
    }
    .erweima /deep/.qrcode{
        width: 173px!important;
    }
    .title{
        display: none;
    }
    .setCode{
        width: 63%;
    }
    .helper {
        position: absolute;
        bottom: 25px;
        font-size: 12px;
        color: #dedede;
        align-items: center;
        text-align: center;
        left: 50%;
        transform: translateX(-50%);
        white-space: nowrap;
    }
     .firstDiv{
        display: flex;
    }
     .secondDiv{
        margin-top: 10px;
    }
    .secondDiv a,.firstDiv a{
        color: white;
        text-decoration: none;
    }
    .helper-each {
        display: flex;
        align-items: center;
        padding-right: 13px;
    }
    .helper-each>li {
        padding: 0 10px;
        border-right: 1px solid #dedede;
    }
    .helper-each>li a {
        color: #dedddd;
        text-decoration: none;
    }
</style>
<style >
   .login-container .el-form-item__label{
        color: white;
    }
</style>

